<template>
    <vs-table
        ref="table"
        v-model="selected"
        search
        :sst="true"
        :data="users"
        @search="search"
        @selected="
            $router.push({ name: 'user_staff', params: { id: $event.id } })
        "
    >
        <div
            slot="header"
            class="flex flex-wrap-reverse items-center flex-grow justify-end"
        >
            <p v-show="searchQuery" class="mr-4 mb-4">
                Search results for '{{ searchQuery }}'
            </p>

            <!-- ITEMS PER PAGE -->
            <vs-dropdown
                vs-trigger-click
                class="cursor-pointer mb-4 mr-4 items-per-page-handler"
            >
                <div
                    class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                >
                    <span class="mr-2"> {{ itemsPerPage }} </span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>
                <vs-dropdown-menu>
                    <vs-dropdown-item @click="$emit('itemsPerPage', 5)">
                        <span>5</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="$emit('itemsPerPage', 10)">
                        <span>10</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="$emit('itemsPerPage', 20)">
                        <span>20</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="$emit('itemsPerPage', 50)">
                        <span>50</span>
                    </vs-dropdown-item>
                </vs-dropdown-menu>
            </vs-dropdown>
        </div>

        <template slot="thead">
            <vs-th>Name</vs-th>
            <vs-th>Email</vs-th>
            <vs-th>Last Login</vs-th>
            <vs-th>Account Created</vs-th>
        </template>

        <template slot-scope="{ data }">
            <tbody>
                <vs-tr :data="user" :key="index" v-for="(user, index) in data">
                    <vs-td v-if="user.details">
                        {{ user.details.full_name }}
                    </vs-td>
                    <vs-td v-else>Profile not completed</vs-td>

                    <vs-td>
                        {{ user.email }}
                    </vs-td>

                    <vs-td>
                        <vx-tooltip
                            v-if="user.last_login"
                            color="black"
                            title="Last Login"
                            :text="formatDateAndTime(user.last_login)"
                            position="bottom"
                        >
                            <span class="flex items-center px-2 py-1 rounded">
                                {{ timeDifference(user.last_login) }}
                            </span>
                        </vx-tooltip>
                    </vs-td>

                    <vs-td>
                        <vx-tooltip
                            v-if="user.date_joined"
                            color="black"
                            title="Account Created"
                            :text="formatDateAndTime(user.date_joined)"
                            position="bottom"
                        >
                            <span class="flex items-center px-2 py-1 rounded">
                                {{ timeDifference(user.date_joined) }}
                            </span>
                        </vx-tooltip>
                    </vs-td>
                </vs-tr>
            </tbody>
        </template>
    </vs-table>
</template>

<script>
import { textFormat } from '@/mixins/TextFormat'
import { tableHelpers } from '@/mixins/tableHelpers'

export default {
    name: 'UsersStaffTable',
    mixins: [textFormat, tableHelpers],
    props: ['data', 'itemsPerPage'],
    computed: {
        users() {
            return this.data.data
        },
    },
    data() {
        return {
            selected: null,
            searchQuery: '',
            status: 'All',
        }
    },
    methods: {
        search(value) {
            if (value.length > 3 || value === '') {
                this.searchQuery = value
                this.$emit('search', value)
            }
        },
        setLimit(status, value) {
            this.status = status
            this.$emit('limit', value)
        },
    },
}
</script>
